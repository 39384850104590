.project-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
	background-color:  #DDE4E7;
	border-radius: 20px;
	padding-bottom: 20px;
  box-shadow: 10px 5px 20px rgba(0, 0, 0, 0.1);
}

.project-section-title {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}

.project {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  padding: 50px;
  width: 80%;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.project-image {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  flex: 0 0 auto;
  /*transform: translateX(-50px);*/
	transform: translateX(-100%	);
}

.project-image.visible {
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}

.project-details {
  margin-left: 50px;
  flex: 1 1 auto;
  /*transform: translateX(50px);*/
	transform: translateX(100%);
	flex-direction: column;
}
/*
.text {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 20px;
		transform: translateX(200%);
		transition: transform 1s ease-out;
	}
*/

.project-details.visible {
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}

.project h3 {
  font-size: 32px;
  margin-bottom: 20px;
}

.project p {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .project {
    flex-direction: column;
  }

  .project-details {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
    transform: translateY(0);
  }
}
