/* Set basic styles for the body and html elements */
body,
html {
	margin: 0;
	padding: 0;
	font-family: Arial, sans-serif;
	height: 100%;
}

/* Set the container height to 100% of the viewport height */
#container {
	min-height: 100%;
	position: relative;
}

/* Style the content section */
main {
	margin-top: 89px;
	background-color: #f5f5f5;
	min-height: calc(100% - 129px);
	/* Subtract header and footer height */
}


/* Style the services section */
#services {
	min-height: auto;
	padding-top: 40px;
	background-color: #DDE4E7;
}

/* Style the about section */
#about {
	min-height: 400px;

	padding-top: 40px;
	background-color:  #b6d0f1;
}

/* Style the contact form */
#contact {
	min-height: 400px;
	padding-top: 40px;
	padding-bottom: 80px;
	background-color:  #DDE4E7;
}