/* Style the footer */
footer {
	background-color: #333;
	color: #fff;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	position: absolute;
	bottom: 0;
	width: 100%;
}

footer p {
	margin: 0;
	font-size: 12px;
}