
/* Style the hero section */
#hero {
	min-height: 500px;
	background-image: url('../images/hero.jpg');
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
}

.hero-content {
	text-align: center;
	width: 80%;
	color: #fff;
	margin: 20px;
	padding: 20px;
	background-color: rgba(193, 193, 193, 0.1);
	box-shadow: 30px 3px 30px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.hero-content h2 {
	font-size: 48px;
	margin-bottom: 20px;
}

.hero-content p {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 40px;
	color:black;
}

.button {
	display: inline-block;
	background-color: #333;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	font-size: 18px;
	text-decoration: none;
}

.button:hover {
	background-color: #444;
}