.aboutus {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding: 0 20px;
	max-width: 800px;
}

.aboutus h1 {
  font-size: 32px;
  margin-bottom: 20px;
	align-items: center;
}

.aboutus p {
  font-size: 24px;
	text-align: center;
}