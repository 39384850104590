header {
	position: fixed;
	background-color: #59B4DE;
	height: 80px;
	top: 0;
	left: 0;
	width: 100%;
	color: white;
	padding: 20px;
	z-index: 999;
}

.header-container {
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-left {
	display: flex;
	align-items: center;
}

.header-left img {
	height: 89px;
	margin-right: 10px;
}

.header-right {
	display: flex;
	align-items: center;
	top: 60px;
}

.links {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100px; /*how far down the nav bar the links will be*/
  right: 0;
  background-color: #59B4DE;
  width: 100%;
}

.links li {
  margin: 10px 0;
  display: block;
	margin-left: 40px;
}

nav ul li a {
	color: #fff;
	text-decoration: none;
	font-size: 1.2rem;
	transition: color 0.2s;
}

nav ul li a:hover {
	color: #ddd;
	border-bottom: 3px solid #d9d5d5;
}

.hamburger {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  display: block;
  order: 1;
	margin-right: 40px;
}

@media (min-width: 769px) {
  .links {
    display: inline;
    position: static;
    background-color: transparent;
    width: auto;
		margin-right: 40px;
  }

  .hamburger {
    display: none;
  }

  nav {
    order: 1;
  }

  .links li {
    margin: 0;
    display: inline;
    /*align-items: center;*/
		margin-right: 10px;
  }
	
  .links li:not(:last-child) {
    margin-right: 20px;
  }

}

.logo {
	font-size: 24px;
	font-weight: bold;
}