.section-content1 {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/*min-height: 400px;*/
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 20px;
	border-radius: 10px;
	opacity: 0;
	transition: opacity 1s ease-out 0.5s;
}

.sectionTitle {
	height: 100%;
	text-align: center;
	color: dark-gray;
}

.tiles-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: lightgray;
	min-height: 400px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	background-color: rgba(255, 255, 255, 0.6);
}

.tileSection {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.tile {
	flex: 1 0 300px;
	width: 300px;
	max-width: 300px;
	background-color: lightgray;
	color: black;
	text-align: center;
	padding: 1rem;
	margin: 1rem;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	background-color: rgba(255, 255, 255, 0.6);
}

.list {
	text-align: left;
}

.list-item {
	margin-bottom: 10px;
	font-size: 18px;
	margin-bottom: 10px;
	color:black;
}